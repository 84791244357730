import React from "react"
import Layout from "../layouts"

import ContactUs from "../images/contact-us.png"

export default () => (
  <Layout>
    <div className="mw-beskar mt5 center ph3 ph4-l flex items-center-l justify-between h-100">
      <div className="quote w-40">
        <h1 className="f-title">We would love to hear from you! &#128075;</h1>
        <h2>
          Reach out to us on:{" "}
          <span className="underline">hello@beskar.tech</span>
        </h2>
      </div>
      <div className="image w-60">
        <img src={ContactUs} alt="contact us" />
      </div>
    </div>
  </Layout>
)
